<template>
  <div
    class="
      q-pa-md q-gutter-md
      fit
      row
      wrap
      justify-center
      items-start
      content-start
    "
  >
    <q-container>
      <div v-if="txn">
        <q-card v-if="txn.token">
          <q-img class="q-pa-md" ratio="1" :src="txn.token.data.image"> </q-img>

          <q-card-section>
            <div class="text-h6">{{ txn.token.data.name }}</div>

            <div class="text-right text-h6 text-orange">
              {{ $t("price") }}: ${{ txn.token.data.price }}
            </div>
          </q-card-section>
        </q-card>

        <q-card v-else>
          <q-img class="q-pa-md" ratio="1" :src="txn.nfts.data.image"> </q-img>
          <q-card-section>
            <div class="text-h6">{{ txn.nfts.data.name }}</div>

            <div class="text-right text-h6 text-orange">
              {{ $t("price") }}: ${{ txn.nfts.data.price }}
            </div>
          </q-card-section>
        </q-card>
      </div>

      <form class="q-mt-md" id="payment-form">
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
        </div>
            <div class="q-ma-md text-center text-h6 text-orange">
          Order total: ${{pay_total/100}}
        </div>
        <button id="submit">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">Pay now</span>
        </button>
        <div id="payment-message" class="hidden"></div>
      </form>
    </q-container>
  </div>
</template>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>

<script>
import { ref, onBeforeMount, onMounted } from "vue";
import { supabase as s } from "../supabase";
import { useRoute } from "vue-router";
import { WEBROOT, APIURL } from "../web3";
import { loadStripe } from "@stripe/stripe-js";
import { stripe_key } from "../stripe";

export default {
  setup() {
    let elements = null;
    const loading = ref(false);
    const email = ref("");
    const id = useRoute().params.oid;
    const tid = useRoute().query.tid;
    const txn = ref(null);
    const stripe = ref(null);
    const pay_total = ref(0);

    onBeforeMount(async () => {
      const { data, error } = await s
        .from("txns")
        .select("*,nfts(*),token(*)")
        .match({ id: id });
      if (error) {
        console.log(error);
        return;
      }
      console.log("txn", data);
      if (data.length < 1) {
        console.log("Error: find order records");
        alert("No order:", id);
        return;
      } else {
        txn.value = data[0];
      }

      if (txn.value.status == "MINTED" || txn.value.status == "PAID") {
        alert("Order finished! Please check status from 'My Orders'.");
        console.log("Order finished! Status:", txn.value.status);
        return;
      }

      let items = [{ contract: txn.value.contract, tid: tid }];

      console.log("stripe_key:",stripe_key)
      //stripe.value = await loadStripe(stripe_live_key);
      stripe.value = await loadStripe(stripe_key);
  
      const init = async () => {
        // if (!txn.value.payment_id) {
        const response = await fetch(APIURL + "/create-payment-intent", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ items }),
        });
        const { clientSecret,order_total} = await response.json();
        pay_total.value = order_total;
        console.log("client secret:", clientSecret);
        console.log("order total:", order_total);


        // update order with this client secret for matching with stripe transactions
        const pid = clientSecret.substr(0, clientSecret.indexOf("_secret"));
        const { data, error } = await s
          .from("txns")
          .update({ payment_id: pid })
          .match({ id: id });
        if (error) {
          console.log("Set order payment_id error.", error);
          alert(error.message);
        } else {
          console.log("Set order payment_id success.", data);
          const appearance = { theme: "stripe" };
          elements = stripe.value.elements({ appearance, clientSecret });
        }
        // } else {
        //     const appearance = { theme: "stripe" };
        //     const saved_secret = txn.value.payment_id
        //     elements = stripe.value.elements({ appearance, saved_secret });
        // }

        const paymentElement = elements.create("payment");
        paymentElement.mount("#payment-element");
      };

      const showMessage = (messageText) => {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        setTimeout(function () {
          messageContainer.classList.add("hidden");
          messageContainer.textContent = "";
        }, 4000);
      };

      const payment_submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { error } = await stripe.value.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: WEBROOT + "/confirm/" + id,
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          showMessage(error.message);
        } else {
          showMessage("An unexpected error occured.");
        }
        setLoading(false);
      };
      // Show a spinner on payment submission
      const setLoading = (isLoading) => {
        if (isLoading) {
          // Disable the button and show a spinner
          document.querySelector("#submit").disabled = true;
          document.querySelector("#spinner").classList.remove("hidden");
          document.querySelector("#button-text").classList.add("hidden");
        } else {
          document.querySelector("#submit").disabled = false;
          document.querySelector("#spinner").classList.add("hidden");
          document.querySelector("#button-text").classList.remove("hidden");
        }
      };
      await init();
      document
        .querySelector("#payment-form")
        .addEventListener("submit", payment_submit);
    });

    return {
      stripe,
      elements,
      loading,
      txn,
      pay_total
    };
  },

  methods: {},

  // ------- UI helpers -------
};
</script>